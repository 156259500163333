<template>
  <el-dialog
    class="dialog-for-how-auth"
    title="购买音乐后如何获得授权书？"
    :visible="dialogVisible"
    :destroy-on-close="true"
    :before-close="handleBeforeClose"
    append-to-body
  >
    <div class="auth-container">
      <p>下载音乐后，您可在【个人中心】-【我的授权】中找到已下载音乐；如果为待激活状态，可点击【激活授权】，选择授权主体以及授权渠道，填写授权项目名称，点击激活授权即可；</p>
      <p class="mb">如果为已激活状态，点击【查看授权】，即可查看并下载已生成的授权书。</p>
      <div><a :href="retailAuthTemplate" target="_blank">零售授权书模板</a></div>
      <!-- <div style="margin-top: 6px"><a :href="userProtocol" target="_blank">企业会员授权书模板</a></div> -->
    </div>
    <div slot="footer">
      <el-button type="primary" @click="handleBeforeClose">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { retailAuthTemplate } from '@/config'

export default {
  name: 'DialogForHowAuth',
  data() {
    return {
      retailAuthTemplate
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
  },
  computed: {
  },
  methods: {
    handleBeforeClose() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-for-how-auth{

  /deep/ .el-dialog{
    width: 600px;
    border-radius: 5px;
    .el-dialog__title{
      padding-left: 16px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
  }
  .auth-container {
    width: 480px;
    margin: 0 auto;
    .mb {
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      color: #333333;
      line-height: 24px;
    }
    a{
      color: #0061F3;
      text-decoration: underline;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      &:active {
        color: #0061F3;
      }
    }
  }
}
</style>
